@import "Main";
@import "Sidebar";
@import "Topbar";
@import "Footer";
@import "Navigation";


.icon {
  display: inline-block;
  width: 2em;
  text-align: center;
}
